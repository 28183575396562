import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from 'gatsby'
import ClientDetail from '../components/clients/clientDetail';
import Section from '../components/layout/section';

const Client = ({ location, ...props }) => {
  const client = get(props, 'data.contentfulKunde')
  return (
    <Section className="detail">
      <div className="container--narrow">
        <ClientDetail data={client} />
        <Link className="back" to="/kunden-leistungen">Zu allen Kunden &amp; Leistungen</Link>
      </div>
    </Section>
  );
}

export default Client;

export const pageQuery = graphql`
  query KundeBySlug($slug: String!) {
    contentfulKunde(slug: { eq: $slug }) {
      name
      url
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      logo {
        fluid(maxWidth: 350,  maxHeight: 350, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          fileName
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      artikel {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        leistung
        template:produkt
        kunde {
          name
          id
        }
        image:heroImage {
          fluid(maxWidth: 350,quality: 90, maxHeight: 196, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`